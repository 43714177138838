<template>
  <div id="container" v-if="fetch_data">
    <section id="search">
      <div class="wrap">
        <div class="cts_box bdr_btm">
          <h2 class="sub_ttl"><span>検索結果</span></h2>
          <form @submit="update" id="form">
            <p class="lead_txt alnC sp_alnL" v-if="false">検索キーワード、絞り込み条件を変えて再検索できます。</p>
            <div class="free_search_box">
              <input type="search" class="free_search" placeholder="検索したい内容を入力" v-model="query"
                     ref="searchInput">
            </div>
            <div class="search_box">
              <div class="button_wrap">
                <input type="submit" class="submit light skeleton" value="内容を変えて再検索">
              </div>
            </div>
          </form>
        </div>

        <!--新検索結果モックここから
         検索結果のみbody にclass 'page_search' を追加してください
         PCの時
         PCタブをクリックでクリックした「label_set」に.active 追加
         表示する検索結果の「search_set」に .active 追加

        SPの時
         「search_set」タップで.active を追加、削除

        懸念点
          SPで前開きの時、そのまま横幅を広げると全タイプの検索結果が見えてしまうかも、、
          SPの時、PCタブの「label_set」の.active と連動させないとそのまま横幅を広げた時に挙動がおかしくなるかも、、
        -->
        <div id="tab"></div>

        <!--PCタブ-->
        <div class="search_head flex">
          <div class="label_set" v-bind:class="{'active': tab === 'contents'}" @click="tabChanged('contents')">
            <div class="label">
              <div class="type">講義</div>
              <div class="s_num">
                <template v-if="fetch_data.hit_count.contents <= 100"><span>{{ fetch_data.hit_count.contents }}</span>件
                </template>
                <template v-else><span>100</span>件以上</template>
              </div>
            </div>
          </div>
          <div class="label_set" v-bind:class="{'active': tab === 'event'}" @click="tabChanged('event')">
            <div class="label">
              <div class="type">イベント</div>
              <div class="s_num">
                <template v-if="fetch_data.hit_count.event <= 100"><span>{{ fetch_data.hit_count.event }}</span>件
                </template>
                <template v-else><span>100</span>件以上</template>
              </div>
            </div>
          </div>
          <div class="label_set" v-bind:class="{'active': tab === 'notice'}" @click="tabChanged('notice')">
            <div class="label">
              <div class="type">事務局通信</div>
              <div class="s_num">
                <template v-if="fetch_data.hit_count.notice <= 100"><span>{{ fetch_data.hit_count.notice }}</span>件
                </template>
                <template v-else><span>100</span>件以上</template>
              </div>
            </div>
          </div>
          <div class="label_set" v-bind:class="{'active': tab === 'social'}" @click="tabChanged('social')">
            <div class="label">
              <div class="type">テーマ別グループ</div>
              <div class="s_num">
                <template v-if="fetch_data.hit_count.social <= 100"><span>{{ fetch_data.hit_count.social }}</span>件
                </template>
                <template v-else><span>100</span>件以上</template>
              </div>
            </div>
          </div>
          <div class="label_set" v-bind:class="{'active': tab === 'media'}" @click="tabChanged('media')">
            <div class="label">
              <div class="type">メディア</div>
              <div class="s_num">
                <template v-if="fetch_data.hit_count.media <= 100"><span>{{ fetch_data.hit_count.media }}</span>件
                </template>
                <template v-else><span>100</span>件以上</template>
              </div>
            </div>
          </div>
          <div class="label_set" v-bind:class="{'active': tab === 'timeline'}" @click="tabChanged('timeline')">
            <div class="label">
              <div class="type">複眼SNS</div>
              <div class="s_num">
                <template v-if="fetch_data.hit_count.timeline <= 100"><span>{{ fetch_data.hit_count.timeline }}</span>件
                </template>
                <template v-else><span>100</span>件以上</template>
              </div>
            </div>
          </div>
          <div class="label_set" v-bind:class="{'active': tab === 'shop'}" @click="tabChanged('shop')">
            <div class="label">
              <div class="type">ショップ</div>
              <div class="s_num">
                <template v-if="fetch_data.hit_count.shop <= 100"><span>{{ fetch_data.hit_count.shop }}</span>件
                </template>
                <template v-else><span>100</span>件以上</template>
              </div>
            </div>
          </div>
        </div>
        <!--PCタブここまで-->

        <!--検索結果講義-->
        <div class="search_set" v-bind:class="{'active': tab === 'contents'}">
          <div class="acc_search_head flex" @click="tabChanged('contents', true)">
            <div class="label">
              <div class="type">講義</div>
              <div class="s_num">{{ fetch_data.hit_count.contents }}件</div>
            </div>
            <span class="btn"></span>
          </div>
          <ContentList v-if="tab === 'contents'" :order="order" :fetch_data="fetch_data" :tab="tab"
                       v-on:pageChanged="pageChanged" v-on:orderChanged="orderChanged" />
        </div>

        <!--検索結果イベント-->
        <div class="search_set" v-bind:class="{'active': tab === 'event'}">
          <div class="acc_search_head flex" @click="tabChanged('event', true)">
            <div class="label">
              <div class="type">イベント</div>
              <div class="s_num">{{ fetch_data.hit_count.event }}件</div>
            </div>
            <span class="btn"></span>
          </div>
          <EventList v-if="tab === 'event'" :order="order" :fetch_data="fetch_data" :tab="tab"
                    v-on:pageChanged="pageChanged" v-on:orderChanged="orderChanged" />
        </div>

        <!--検索結果事務局通信-->
        <div class="search_set" v-bind:class="{'active': tab === 'notice'}">
          <div class="acc_search_head flex" @click="tabChanged('notice', true)">
            <div class="label">
              <div class="type">事務局通信</div>
              <div class="s_num">{{ fetch_data.hit_count.notice }}件</div>
            </div>
            <span class="btn"></span>
          </div>
          <ContentList v-if="tab === 'notice'" :order="order" :fetch_data="fetch_data" :tab="tab"
                       v-on:pageChanged="pageChanged" v-on:orderChanged="orderChanged" />
        </div>

        <!--検索結果テーマ別グループ-->
        <div class="search_set" v-bind:class="{'active': tab === 'social'}">
          <div class="acc_search_head flex" @click="tabChanged('social', true)">
            <div class="label">
              <div class="type">テーマ別グループ</div>
              <div class="s_num">{{ fetch_data.hit_count.social }}件</div>
            </div>
            <span class="btn"></span>
          </div>
          <ContentList v-if="tab === 'social'" :order="order" :fetch_data="fetch_data" :tab="tab"
                       v-on:pageChanged="pageChanged" v-on:orderChanged="orderChanged" />
        </div>

        <!--検索結果メディア-->
        <div class="search_set" v-bind:class="{'active': tab === 'media'}">
          <div class="acc_search_head flex" @click="tabChanged('media', true)">
            <div class="label">
              <div class="type">メディア</div>
              <div class="s_num">{{ fetch_data.hit_count.media }}件</div>
            </div>
            <span class="btn"></span>
          </div>
          <ContentList v-if="tab === 'media'" :order="order" :fetch_data="fetch_data" :tab="tab"
                       v-on:pageChanged="pageChanged" v-on:orderChanged="orderChanged" />
        </div>

        <!--検索自分のタイムライン-->
        <div class="search_set" v-bind:class="{'active': tab === 'timeline'}">
          <div class="acc_search_head flex" @click="tabChanged('timeline', true)">
            <div class="label">
              <div class="type">複眼SNS</div>
              <div class="s_num">{{ fetch_data.hit_count.timeline }}件</div>
            </div>
            <span class="btn"></span>
          </div>
          <ContentList v-if="tab === 'timeline'" :order="order" :fetch_data="fetch_data" :tab="tab"
                       v-on:pageChanged="pageChanged" v-on:orderChanged="orderChanged" />
        </div>

        <!--検索ショップ-->
        <div class="search_set" v-bind:class="{'active': tab === 'shop'}">
          <div class="acc_search_head flex" @click="tabChanged('shop', true)">
            <div class="label">
              <div class="type">ショップ</div>
              <div class="s_num">{{ fetch_data.hit_count.shop }}件</div>
            </div>
            <span class="btn"></span>
          </div>
          <ShopList v-if="tab === 'shop'" :order="order" :fetch_data="fetch_data" :tab="tab"
                       v-on:pageChanged="pageChanged" v-on:orderChanged="orderChanged" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import SnsCommons from '@/components/sns/SnsCommons.vue'

import ContentList from '@/views/sns/search/ContentList'
import ShopList from '@/views/sns/search/ShopList'
import EventList from '@/views/sns/search/EventList'


export default {
  mixins: [Libraries, SnsCommons],
  components: {
    ContentList,
    ShopList,
    EventList
  },
  data() {
    return this.initialize()
  },
  watch: {
    $route(/*to, from*/) {
      this.reset()
    }
  },
  methods: {
    initialize() {
      return {
        fetch_data: null,
        query: this.$route.query.query,
        page: this.$route.query.page == null ? 1 : Number(this.$route.query.page),
        order: this.$route.query.order == null ? '' : this.$route.query.order,
        tab: this.$route.query.tab == null ? 'contents' : this.$route.query.tab,
        infiniteLoadingEnabled: false
      }
    },
    reset() {
      Object.assign(this.$data, this.initialize());
    },

    // 無限スクロール
    infiniteHandler(/*$state*/) {
      if (this.fetch_data.pagination.last_page) {
        //$state.complete()
      } else {
        this.pageChanged(this.page + 1)
      }
    },

    fetchData() {
      if (this.fetch_data === null || !this.infiniteLoadingEnabled) {
        this.scrollTop()
        this.startAjax()
      }
      this.axios
          .get(`${this.env.api_base_url}sns/search.json`, {
            params: {
              page: this.page,
              query: this.query,
              tab: this.tab,
              order: this.order,
            },
          })
          .then(response => {
            // console.log(response.data)
            // this.addFrontParams(response.data)
            this.query = response.data.query
            if (this.page === 1 || this.fetch_data === null || !this.infiniteLoadingEnabled) {
              this.fetch_data = response.data
            } else {
              this.fetch_data.posts = this.fetch_data.posts.concat(response.data.posts)
              this.fetch_data.pagination = response.data.pagination
            }
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            if (this.$refs.infiniteLoading) this.$refs.infiniteLoading.stateChanger.loaded()
            this.finishAjax()
            //this.$refs.searchInput.focus()
          })
    },
    comment_addable() {
      return true
    },
    search_space_title(post) {
      return post.space_title
    },

    update(event) {
      event.preventDefault()
      this.pageChanged(1)
    },
    // tab変更
    tabChanged(tab, sp = false) {
      if (sp && this.tab === tab) {
        this.tab = null;
      } else {
        this.tab = tab
        this.pageChanged(1)
      }
    },
    orderChanged(order) {
      this.order = order
      this.pageChanged(1)
    },
    // ページネーション
    pageChanged(page) {
      let order = this.order
      if (this.tab === 'event') {
        if (order === '') {
          order = 'published_at_desc'
        }
      } else if (this.tab === 'contents' || this.tab === 'notice' || this.tab === 'media' || this.tab === 'social' || this.tab === 'timeline') {
        if (order === 'published_at_desc') {
          order = ''
        }
      }

      this.page = page
      this.order = order

      let query = {}
      if (this.query !== '') query.query = this.query
      if (this.order !== '') query.order = this.order
      query.tab = this.tab
      history.pushState(null, null, '?' + window.$.param(query));

      this.fetchData()

      /*
      let query = {}
      if (this.order !== '') query.order = this.order
      if (page !== 1) query.page = page
      query.query = this.query
      query.tab = this.tab
      query.order = order

      this.$router.push({
        name: 'SnsSearch',
        params: this.$route.params,
        query: query,
      })
      */
    }
  }
}
</script>
