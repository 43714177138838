<template>
  <div class="search_result_box">
    <div class="sort flex" v-if="fetch_data.posts.length > 0">
      <p>並び替え</p>
      <select v-model="order" v-on:change="$emit('orderChanged', order)">
        <option value="">オススメ順</option>
        <!--<option value="category_asc">ジャンル順</option>-->
        <option value="published_at_desc">価格の安い順番</option>
        <option value="published_at_asc">価格の高い順番</option>
      </select>
    </div>
    <div class="search_result">
      <div class="result_box" v-for="(post, key) in fetch_data.posts" :key="key" v-bind:id="'shop-' + post.id"
           @click="searchClick(post)">
        <div class="tag" style="width: fit-content; margin-bottom: 6px">{{ post.category }}</div>
        <div class="header flex">
          <div class="detail flex">
            <div class="icon">
              <img v-bind:src="post.image_files[0]" alt="">
            </div>
            <div class="inner">
              <div class="room_name">
                {{ post.title }}
              </div>
              <div class="price" >¥ {{ delimited(post.price) }}（税込）</div>
            </div>
          </div>
        </div>
        <div class="link_area">
          <div class="title" v-if="false"></div>
          <div class="text_body" v-html="post.body_hit_text"></div>
        </div>
      </div>
      <template v-if="fetch_data.posts.length === 0">
        <div class="mt20 mb20 alnC">
          一致する投稿は見つかりませんでした。
        </div>
      </template>
      <template v-else>
        <Pagination :pagination="fetch_data.pagination" v-on:changed="$emit('pageChanged', $event)"/>
      </template>
    </div>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import Pagination from "@/components/Pagination.vue";

export default {
  components: {Pagination},
  mixins: [Libraries],
  props: {
    fetch_data: null,
    tab: null,
    order: null
  },
  methods: {
    searchClick(post) {
      this.$router.push({name: 'ShoppingArticle', params: {id: post.id}, query: {query: this.fetch_data.query}})
    }
  }
}
</script>