<template>
  <div class="search_result_box">
    <div class="sort flex" v-if="fetch_data.posts.length > 0">
      <p>並び替え</p>
      <select v-model="order" v-on:change="$emit('orderChanged', order)">
        <option value="">投稿の新しい順</option>
        <option value="published_at_asc">投稿の古い順</option>
        <option value="last_updated_at_desc">更新順</option>
      </select>
    </div>

    <div class="search_result">
      <div class="result_box" v-for="(post, key) in fetch_data.posts" :key="key"
           v-bind:id="'post-' + tab + '-' + post.id" @click="searchClick(post)">

        <div class="tag" style="width: fit-content; margin-bottom: 6px">{{ post.space_title }}</div>
        <div class="header flex">
          <div class="detail flex">
            <div class="icon">
                <img v-bind:src="post.user.profile_image_url" alt="">
            </div>
            <div class="inner">
              <div class="room_name">
                {{ post.user.sns_user_name }}
              </div>
              <div class="date">{{ moment(post.published_at, 'YYYY/MM/DD HH:mm') }}</div>
            </div>
          </div>
        </div>
        <div class="link_area">
          <div class="title">{{ post.title }}</div>
          <div class="text_body" v-if="post.object.body_hit_text" v-html="post.object.body_hit_text"></div>
          <template v-if="post.content_types.includes('content_type_video') || post.content_types.includes('content_type_pdf') || post.content_types.includes('content_type_audio')">
            <p class="comment_txt">添付ファイル内に検索ワードが含まれています</p>
            <div class="attached flex">
              <div class="list_icon movie" v-if="post.content_types.includes('content_type_video')"><span class="marker">動画</span></div>
              <div class="list_icon pdf" v-if="post.content_types.includes('content_type_pdf')"><span class="marker">PDF</span></div>
              <div class="list_icon voice" v-if="post.content_types.includes('content_type_audio')"><span class="marker">音声</span></div>
            </div>
          </template>
        </div>
        <p class="comment_txt" v-if="post.comments">この投稿へのコメントです</p>
        <div class="comment flex" v-for="comment in post.comments" :key="'comment_' + comment.comment_id">
          <div class="icon">
            <img v-bind:src="comment.user.profile_image_url" alt="">
          </div>
          <div class="comment-box">
            <div class="user_name">{{ comment.user.sns_user_name }}</div>
            <div class="date">{{ moment(comment.date, 'YYYY/MM/DD HH:mm') }}</div>
            <div class="body" v-html="comment.body_hit_text"></div>
          </div>
        </div>
      </div>
      <template v-if="fetch_data.posts.length === 0">
        <div class="mt20 mb20 alnC">
          一致する投稿は見つかりませんでした。
        </div>
      </template>
      <template v-else>
        <Pagination :pagination="fetch_data.pagination" v-on:changed="$emit('pageChanged', $event)"/>
      </template>
    </div>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import Pagination from '@/components/Pagination.vue'

export default {
  mixins: [Libraries],
  components: {
    Pagination
  },
  props: {
    fetch_data: null,
    tab: null,
    order: null
  },
  methods: {
    searchClick(post) {
      this.$router.push({name: 'SnsPost', params: {post_id: post.id}, query: {query: this.fetch_data.query}})
    }
  }
}
</script>